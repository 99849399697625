import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className="main-footer bg-brand-gray2 text-brand-gray3 text-sm">
			<section className="flex flex-col items-center sm:flex-row sm:justify-between max-w-screen-xl mx-auto sm:items-start px-3 pt-6 pb-8">
				<div className="flex flex-col sm:w-1/3 items-center sm:items-start pb-8 sm:pb-0">
					<h2 className="text-white uppercase font-semibold text-lg mb-1">
						DGITAL Labs <Trans>Ltd</Trans>.
					</h2>
					<div className="w-full sm:w-3/4 ms:w-full">
						<Trans>Company registration number</Trans>: 01-09-207662
					</div>
					<div>
						<Trans>VAT number</Trans>: 25310612-2-42
					</div>
				</div>
				<div id="contact" className="flex items-center flex-col sm:w-1/3 pb-8 sm:pb-0">
					<h2 className="text-white uppercase font-semibold text-lg mb-1">
						<Trans>Contact us</Trans>
					</h2>
					<div className="flex items-center">
						<span>
							<svg
								className="h-4 w-4 fill-current mr-2"
								viewBox="0 0 1792 1792"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1600 1240q0 27-10 70.5t-21 68.5q-21 50-122 106-94 51-186 51-27 0-53-3.5t-57.5-12.5-47-14.5-55.5-20.5-49-18q-98-35-175-83-127-79-264-216t-216-264q-48-77-83-175-3-9-18-49t-20.5-55.5-14.5-47-12.5-57.5-3.5-53q0-92 51-186 56-101 106-122 25-11 68.5-21t70.5-10q14 0 21 3 18 6 53 76 11 19 30 54t35 63.5 31 53.5q3 4 17.5 25t21.5 35.5 7 28.5q0 20-28.5 50t-62 55-62 53-28.5 46q0 9 5 22.5t8.5 20.5 14 24 11.5 19q76 137 174 235t235 174q2 1 19 11.5t24 14 20.5 8.5 22.5 5q18 0 46-28.5t53-62 55-62 50-28.5q14 0 28.5 7t35.5 21.5 25 17.5q25 15 53.5 31t63.5 35 54 30q70 35 76 53 3 7 3 21z" />
							</svg>
						</span>
						<span>+36 (1) 550 7500</span>
					</div>
					<div>
						<a
							href="mailto:info@dgital.com"
							itemProp="email"
							className="flex items-center hover:text-brand-green"
						>
							<svg
								className="h-4 w-4 fill-current mr-2"
								viewBox="0 0 1792 1792"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z" />
							</svg>
							<span className="email-address">info@dgital.com</span>
						</a>
					</div>
				</div>
				<div className="flex flex-col sm:w-1/3 items-center sm:items-end">
					<h2 className="text-white uppercase font-semibold text-lg mb-1">
						<Trans>Address</Trans>
					</h2>
					<div className="hover:text-brand-green">
						<a
							href="https://www.google.hu/maps/place/Laurus/@47.4659711,19.1247815,17z/data=!4m13!1m7!3m6!1s0x4741c32fd6b9ac2f:0x8412e27e3794f0e6!2zQnVkYXBlc3QsIEvFkcOpciB1LiAyLCAxMTAz!3b1!8m2!3d47.4665987!4d19.1263212!3m4!1s0x0:0x6d267a2f2dc1e06c!8m2!3d47.4663967!4d19.1270202?hl=en"
							className="flex flex-col items-end"
						>
							<div itemScope itemType="http://schema.org/address" className="flex items-center">
								<svg
									className="h-4 w-4 fill-current mr-1"
									viewBox="0 0 1792 1792"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1152 640q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm256 0q0 109-33 179l-364 774q-16 33-47.5 52t-67.5 19-67.5-19-46.5-52l-365-774q-33-70-33-179 0-212 150-362t362-150 362 150 150 362z" />
								</svg>
								<span itemProp="PostalAddress">Kőér utca 2/a</span>
							</div>
							<div itemScope itemType="http://schema.org/postalAddress">
								H-<span itemProp="PostalCode">1103</span> Budapest
							</div>
						</a>
					</div>
				</div>
			</section>
			<section className="bg-brand-gray1">
				<div className="flex flex-col items-center sm:grid sm:grid-cols-3 max-w-screen-xl mx-auto px-3 pb-2 pt-3">
					<div className="mb-2">
						<Link to="/">
							<img className="h-6" src="/dgital_logo_white.svg" alt="DGITAL LABS KFT" />
						</Link>
					</div>
					<div className="mb-2 text-center">
						<Link className="hover:text-brand-green" to="/cookie-policy">
							<Trans>Cookie Policy</Trans>
						</Link>
						&nbsp;|&nbsp;
						<Link className="hover:text-brand-green" to="/privacy-policy">
							<Trans>Privacy Policy</Trans>
						</Link>
					</div>
					<div className="text-white justify-end flex mb-2">
						<div>
							<a href="https://www.facebook.com/dgitalcom">
								<svg
									className="h-8 w-8 fill-current hover:text-brand-green mr-4"
									viewBox="0 0 1792 1792"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1376 128q119 0 203.5 84.5t84.5 203.5v960q0 119-84.5 203.5t-203.5 84.5h-188v-595h199l30-232h-229v-148q0-56 23.5-84t91.5-28l122-1v-207q-63-9-178-9-136 0-217.5 80t-81.5 226v171h-200v232h200v595h-532q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960z" />
								</svg>
							</a>
						</div>
						<div>
							<a href="https://www.linkedin.com/company/dgital-com">
								<svg
									className="h-8 w-8 fill-current hover:text-brand-green"
									viewBox="0 0 1792 1792"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z" />
								</svg>
							</a>
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
