import React, { useState, useEffect } from "react";
import { Link, Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

interface Menu {
	title: string;
	slug: string;
}

const LOCALIZATION_ENABLED = false;

const Header = () => {
	let path = "";
	if (typeof window !== "undefined") {
		path = window.location.pathname;
	}

	const [client, setClient] = useState(false);

	useEffect(() => {
		if (path.indexOf("/products") > -1) {
			setProductListVisible(true);
		}
		setClient(true);
	}, []);

	const [menuVisible, setMenuVisible] = useState(false);
	const [productListVisible, setProductListVisible] = useState(false);
	const { languages, changeLanguage } = useI18next();
	const { t } = useTranslation();

	/* Navbar buttons without Products */
	const MENUS: Menu[] = [
		{ title: t("Services"), slug: "/services" },
		{ title: t("Industries"), slug: "/industries" },
		{ title: t("Blog"), slug: "/blog" },
		{ title: t("Jobs"), slug: "/jobs" },
		{ title: t("Contact"), slug: "/#contact" },
	];

	/* Products list*/
	const ProductList = [
		{
			title: t("DEEP Travel Reservation"),
			slug: "/products/travel-reservation-system",
		},
		{
			title: t("DEEP Airline Booking Engine"),
			slug: "/products/airline-booking-engine",
		},
		{
			title: t("DEEP Travel Connector"),
			slug: "/products/travel-connector",
		},
		{
			title: t("DEEP Business Travel"),
			slug: "/products/business-travel-management",
		},
	];

	return (
		<header className="sticky top-0 z-30 select-none">
			<div className="h-16 sm:flex sm:justify-between sm:items-center shadow-sm bg-white">
				<div className="flex items-center h-full justify-between md:p-5 sm:py-5 ml-4">
					<div className="flex items-center">
						<Link to="/" className="h-16 pt-2 pb-1 sm:py-3 md:pt-2 md:pb-1">
							<img src="/main_logo.png" className="h-full" alt="Logo" />
						</Link>
					</div>

					<div className="sm:hidden mr-8">
						<button
							onClick={() => setMenuVisible(!menuVisible)}
							type="button"
							className="block text-gray-600 focus:outline-none"
						>
							<div className={menuVisible ? "block" : "hidden"}>
								<svg
									className="h-4 w-4 fill-current hover:text-brand-green"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
								</svg>
							</div>
							<div className={menuVisible ? "hidden" : "block"}>
								<svg
									className="h-4 w-4 fill-current hover:text-brand-green"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
								</svg>
							</div>
						</button>
					</div>
				</div>
				<nav
					className={`${
						menuVisible ? "block" : "hidden"
					} relative sm:flex bg-white sm:bg-transparent sm:h-full sm:pb-0 z-10 shadow-inner sm:shadow-none`}
				>
					{/* Products button */}
					<div
						onClick={() => setProductListVisible(!productListVisible)}
						className={`menu flex justify-between relative p-4 sm:p-0 flex items-center cursor-pointer ${
							client && path.indexOf("/products") > -1 ? "active" : ""
						}`}
					>
						<h1 className="uppercase font-medium lg:text-lg text-brand-grayt mr-1 md:mx-2 z-20">
							<Trans>Products</Trans>
						</h1>
						<h1
							className={`${
								path.indexOf("/products") > -1 || productListVisible ? "-rotate-90" : "rotate-90"
							} block transform sm:hidden font-bold text-lg text-brand-grayt z-20 mr-5`}
						>
							&#10095;
						</h1>
						<div className="absolute bottom-0 left-0 w-full h-0"></div>
					</div>
					{/* Product list mobile */}
					<div
						className={`${
							(menuVisible && productListVisible) || (menuVisible && path.indexOf("/products") > -1)
								? "block"
								: "hidden"
						} bg-white`}
					>
						{ProductList.map((product) => (
							<Link
								key={`${product.title}${client}`}
								to={product.slug}
								className={`menu sm:hidden flex justify-between relative p-4 items-center shadow-inner ${
									path.indexOf(product.slug) > -1 ? "active" : ""
								}`}
							>
								<h1 className="uppercase font-medium text-brand-grayt sm:mx-2 z-20 pl-8">
									{product.title}
								</h1>
								<div className="absolute bottom-0 left-0 w-full h-0"></div>
							</Link>
						))}
					</div>
					{/* Navbar buttons */}
					{MENUS.map((item) => (
						<Link
							key={`${item.slug}${client}`}
							to={item.slug}
							className={`menu flex justify-between relative p-4 sm:p-0 items-center shadow-inner sm:shadow-none ${
								path.indexOf(item.slug) > -1 ? "active" : ""
							}`}
						>
							<h1 className="uppercase font-medium text-sm md:text-base lg:text-lg text-brand-grayt mx-1 md:mx-2 z-20">
								{item.title}
							</h1>
							<div className="absolute bottom-0 left-0 w-full h-0"></div>
						</Link>
					))}
					{LOCALIZATION_ENABLED
						? languages.map((lng) => (
								<button
									key={lng}
									className={`hidden sm:block text-base hover:text-brand-green focus:outline-none shadow-inner bg-white px-3 uppercase`}
									onClick={() => {
										changeLanguage(lng);
									}}
								>
									{lng}
								</button>
						  ))
						: ""}
				</nav>
			</div>
			{/* Product list desktop */}
			<div
				className={`${
					client && (productListVisible || path.startsWith("/products")) ? "hidden sm:block" : "hidden"
				} bg-brand-gray5 absolute w-full -bottom-50 z-30 shadow-inner px-2 `}
			>
				<div className="container m-auto flex justify-between">
					{ProductList.map((product) => (
						<Link
							key={`${product.title}${client}`}
							to={product.slug}
							className={`product-list xl:px-3 ${path.startsWith(product.slug) ? "active" : ""}`}
						>
							<h1 className="uppercase text-center text-white z-20 my-2 mr-2 lg:my-3">{product.title}</h1>
						</Link>
					))}
				</div>
			</div>
		</header>
	);
};

export default Header;
